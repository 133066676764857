
import { updateAppConfig } from '#app/config'
import { defuFn } from 'defu'

const inlineConfig = {
  "nuxt": {
    "buildId": "f4ecd192-d59c-45d3-ba56-8068f730472e"
  }
}

// Vite - webpack is handled directly in #app/config
if (import.meta.hot) {
  import.meta.hot.accept((newModule) => {
    updateAppConfig(newModule.default)
  })
}

import cfg0 from "/opt/build/repo/.nuxt/app-configs/cfg1.ts"
import cfg1 from "/opt/build/repo/.nuxt/app-configs/cfg2.ts"
import cfg2 from "/opt/build/repo/.nuxt/app-configs/cfg3.ts"
import cfg3 from "/opt/build/repo/.nuxt/app-configs/cfg4.ts"
import cfg4 from "/opt/build/repo/.nuxt/app-configs/cfg5.ts"
import cfg5 from "/opt/build/repo/.nuxt/app-configs/cfg6.ts"

export default /*@__PURE__*/ defuFn(cfg0, cfg1, cfg2, cfg3, cfg4, cfg5, inlineConfig)
